import React, { useContext, useEffect, useRef, useState } from 'react'
import { API_URL } from '../constants'
import { NetworkContext, TypeNetworkContext } from '../contexts'
import { useId } from './hooks'
import Icon from './Icon'
import Spinner from './Spinner'

type TypeFileInputProps = any

const FileInput = React.memo(function FileInput(props: TypeFileInputProps) {
    const { payload, onSuccess, ...rest } = props
    const id = useId()
    const [wantToUpload, setWantToUpload] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const fileRef = useRef<any>(null)
    const { upload_thread_count, upload_thread_max, setNetworkContext } = useContext(NetworkContext)

    const onChange = (e: any) => {
        console.log(Array.from(e.target.files))
        const formData = new FormData()
        Array.from(e.target.files).map((f: any) => {
            formData.append(f.name, f)
        })
        payload && Object.keys(payload).map((key: string) => formData.append(key, payload[key]))
        fileRef.current = formData
        setWantToUpload(true)
    }

    const upload = () => {
        console.log('Uploading, upload count:', upload_thread_count)
        setIsUploading(true)
        fetch(API_URL + 'upload-images', {
            method: 'POST',
            body: fileRef.current,
        })
            .then((r) => r.json())
            .then((r) => {
                setIsUploading(false)

                setNetworkContext((nc: TypeNetworkContext) => ({ ...nc, upload_thread_count: nc.upload_thread_count - 1 }))
                console.log('File upload finished')
                onSuccess?.(r)
            })
    }

    useEffect(() => {
        let interval: any
        if (wantToUpload) {
            interval = setInterval(() => {
                console.log({ upload_thread_count })
                if (upload_thread_count < upload_thread_max) {
                    // GO!
                    clearInterval(interval)
                    interval = null
                    setNetworkContext((nc: TypeNetworkContext) => ({ ...nc, upload_thread_count: nc.upload_thread_count + 1 }))
                    upload()
                    setWantToUpload(false)
                }
            }, 1000)
        }
        return () => {
            if (interval !== null) {
                clearInterval(interval)
                interval = null
            }
        }
    }, [wantToUpload, upload_thread_count])

    return (
        <div className="flex">
            <input id={id} type="file" {...rest} onChange={onChange} />
            <label
                htmlFor={id}
                className={'btn btn-default btn-xs ' + (props.className || '') + (wantToUpload || isUploading ? ' pointer-events-none disabled' : '')}
            >
                <span className="flex items-center justify-center">
                    {wantToUpload ? (
                        'Pending...'
                    ) : (
                        <>
                            {isUploading ? (
                                <>
                                    <Spinner className="mr-2" /> Loading
                                </>
                            ) : (
                                <>
                                    <Icon name="upload" className="mr-1" />
                                    Upload
                                </>
                            )}
                        </>
                    )}
                </span>
            </label>
        </div>
    )
})

export default FileInput
