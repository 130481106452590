import React, { useState, useEffect } from 'react'

const useFetch = function <D>({ url }: { url: string }): any | D {
    const [value, setValue] = useState<null | D>(null)
    useEffect(() => {
        fetch(url)
            .then((r) => r.json())
            .then((r) => setValue(r))
    }, [url])
    return value
}

export default useFetch
