import { useAdmin } from './hooks'

const RequirePermission = (props: any) => {
    const { role_array } = useAdmin()
    // console.log({ role_array })
    const auth = (props.role_array || []).reduce((acc: boolean, role: string) => {
        return (acc = acc || role_array.includes(role))
    }, false)
    return auth ? props.children : null
}

export default RequirePermission
