import { useState, useEffect, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { API_URL } from '../constants'
import { LoadingIndicator } from 'react-select/src/components/indicators'
import { AdminContext } from '../contexts'

const ProtectedRoute = (props: any) => {
    const { children, ...rest } = props
    const { authorized, setAdmin } = useContext(AdminContext)
    useEffect(() => {
        fetch(API_URL + 'verify', {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({ token: sessionStorage.getItem('ps_token') }),
        })
            .then((r) => r.json())
            .then((r) => {
                console.log('verified', r)
                if (r.result === true) {
                    setAdmin((s: any) => ({ ...s, authorized: true }))
                } else {
                    setAdmin((s: any) => ({ ...s, authorized: false }))
                }
            })
            .catch(console.error)
    }, [])
    return (
        <Route
            {...rest}
            render={(props) => {
                return authorized ? children : authorized === false ? <Redirect to="/login" /> : 'loading'
            }}
        />
    )
}
export default ProtectedRoute
