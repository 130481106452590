import React from 'react'
import { TypeConfigContext, TypeLocation, TypeNotification } from './types'

const dummyFn = () => {}

export const defaultConfigContextValue: TypeConfigContext = {
    base: '/',
    title: '',
    root: { label: '', image_menu_id: '0' },
    image_start: 0,
    image_end: 30,
    display_logo: false,
    display_navigation_logo: false,
    logo_href: '',
    color_array: [],
    image_array: [],
    file_array: [],
    image_menu_array: [{ id: '0', extension: '.jpg', src: './', alt: '', key: '1', color: '#FFFFFF' }],
    image_menu_zone_array: [],
    static_page_array: [],
    sequence_group_array: [{ id: '1', label: '1' }],
    sequence_array: [],
    sequence_overlay_array: [],
    click_zone_array: [],
    setConfig: dummyFn,
    refresh: dummyFn,
    saveConfig: dummyFn,
    sequence_button_array: [],
    progressive_loading: false,
    concatenated_click_zones: false,
    sequence_back_button: false,
    click_zone_text_field_array: [],
}
export const ConfigContext = React.createContext(defaultConfigContextValue)

export type TypeAppContext = {
    location?: TypeLocation
    selected_click_zone_type_id_array?: string[]
    map_type?: string
    setState: Function
}
const defaultAppContext = { setState: dummyFn }
export const AppContext = React.createContext<TypeAppContext>(defaultAppContext)

export type TypeAdminContext = {
    enabled: boolean
    edit: boolean
    selected: any
    displaying_sequence: boolean
    admin_overlay: boolean
    setSelected: Function
    setAdmin: Function
    authorized: undefined | boolean
    role_array: string[]
}
export const defaultAdminContextValue: TypeAdminContext = {
    enabled: true,
    edit: false,
    selected: null,
    displaying_sequence: false,
    admin_overlay: false,
    setSelected: dummyFn,
    setAdmin: dummyFn,
    authorized: undefined,
    role_array: [],
}
export const AdminContext = React.createContext<TypeAdminContext>(defaultAdminContextValue)

export type TypeNotificationsContext = {
    notification_array: TypeNotification[]
    setNotificationArray: Function
    refresh: Function
}

export const defaultNotificationsContextValue: TypeNotificationsContext = {
    notification_array: [],
    setNotificationArray: dummyFn,
    refresh: dummyFn,
}

export const NotificationsContext = React.createContext<TypeNotificationsContext>(defaultNotificationsContextValue)

export type TypeDisplayContext = {
    left?: string
    top?: string
    width?: string
    height?: string
    setDisplayContextValue: Function
}
const defaultDisplayContext = { setDisplayContextValue: dummyFn }
export const DisplayContext = React.createContext<TypeDisplayContext>(defaultDisplayContext)

export type TypeNetworkContext = {
    upload_thread_count: number
    upload_thread_max: number
    setNetworkContext: Function
}
const defaultNetworkContext = { upload_thread_count: 0, upload_thread_max: 2, setNetworkContext: dummyFn }
export const NetworkContext = React.createContext<TypeNetworkContext>(defaultNetworkContext)
