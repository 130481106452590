import { useState } from 'react'
import { API_URL } from '../constants'
import Button from './Button'
import Input from './Input'
import Page from './Page'
import { useAdmin, useInput } from './hooks'
import { useHistory } from 'react-router-dom'

const Login = () => {
    const usernameInput = useInput()
    const passwordInput = useInput()

    const history = useHistory()
    const { setAdmin } = useAdmin()

    const login = (username: string, password: string) => {
        setIsError(false)
        fetch(API_URL + 'login', {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({ username, password }),
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.result === true) {
                    sessionStorage.setItem('ps_token', r.data.token)
                    sessionStorage.setItem('ps_role_array', r.data.role_array)
                    setAdmin((s: any) => ({ ...s, authorized: true, role_array: r.data.role_array }))
                    history.push('/settings')
                }
            })
            .catch((error) => {
                setIsError(true)
                console.error(error)
            })
    }

    const [isError, setIsError] = useState(false)

    return (
        <Page>
            <form
                className="flex flex-col items-center justify-center h-100 w-100 my-12"
                onSubmit={(e) => {
                    e.preventDefault()
                    login(usernameInput.value, passwordInput.value)
                }}
                method="POST"
            >
                <div className="flex flex-col w-64">
                    Login
                    <Input type="text" className="w-64" {...usernameInput.bind} />
                </div>
                <div className="flex flex-col mt-3 w-64">
                    Password
                    <Input type="password" className="w-64" {...passwordInput.bind} />
                </div>
                <div className="flex mt-5 w-64">
                    <Button
                        className="w-full justify-center uppercase"
                        onClick={() => {
                            login(usernameInput.value, passwordInput.value)
                        }}
                    >
                        Login
                    </Button>
                </div>
                {isError && <div className="text-red-500">Invalid credentials</div>}
            </form>
        </Page>
    )
}

export default Login
