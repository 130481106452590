import React, { useMemo, useState } from 'react'
import Link from './Link'
import { DEFAULT_NAV_ITEM_COLOR, DEFAULT_NAV_ITEM_TEXT_COLOR, DEFAULT_NAV_ITEM_EMPHASIS_COLOR, DEFAULT_NAV_ITEM_EMPHASIS_TEXT_COLOR } from '../constants'
import useConfig from '../hooks/useConfig'
import useGetParams from '../hooks/useGetParams'
import { useAdmin } from './hooks'
import Image from './Image'

type TypeNavigationProps = {}

const HOME_BUTTON = true

const Navigation = React.memo(function Navigation(props: TypeNavigationProps) {
    const {
        display_sequence_navigation_menu = true,
        sequence_group_array,
        sequence_array: global_sequence_array,
        click_zone_array,
        color_array = [],
        display_logo,
        logo_href = '/',
        sequence_back_button = false,
    } = useConfig()
    const { sequence_group_id, sequence_id, sequence, direct_url } = useGetParams()
    const sequence_array = sequence_group_id ? global_sequence_array.filter((l) => l.sequence_group_id === sequence_group_id) : global_sequence_array
    const nav_item_color = useMemo(() => color_array?.find((c) => c.id === 'navigation'), [])
    const nav_item_emphasis_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_emphasis'), [])
    const nav_item_text_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_text'), [])
    const nav_item_emphasis_text_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_emphasis_text'), [])
    const nav_item_style = {
        backgroundColor: nav_item_color?.color ?? DEFAULT_NAV_ITEM_COLOR,
        color: nav_item_text_color?.color ?? DEFAULT_NAV_ITEM_TEXT_COLOR,
    }
    const nav_item_emphasis_style = {
        backgroundColor: nav_item_emphasis_color?.color ?? DEFAULT_NAV_ITEM_EMPHASIS_COLOR,
        color: nav_item_emphasis_text_color?.color ?? DEFAULT_NAV_ITEM_EMPHASIS_TEXT_COLOR,
    }

    const { enabled } = useAdmin()

    const source_click_zone = (sequence ? [sequence, ...sequence_array] : sequence_array).reduce((acc: any, s) => {
        acc = acc || click_zone_array.find((cz) => cz.target_sequence_id === s.id)
        return acc
    }, null)
    const source_sequence = global_sequence_array.find((s) => s.id === source_click_zone?.sequence_id)
    const source_sequence_group = sequence_group_array.find((sg) => sg.id === source_sequence?.sequence_group_id)

    return direct_url ? null : (
        <nav id="sequence-nav" className=" absolute top-0 left-0">
            <ul className={'pl-4 ' + (enabled ? 'pt-20' : 'pt-8')}>
                {(display_logo && (
                    <li className={'fixed top-0 right-0 p-3 ' + (enabled ? 'mt-16' : 'mt-12')}>
                        <Link to={logo_href}>
                            <Image src={`logo.png`} />
                        </Link>
                    </li>
                )) ||
                    (display_sequence_navigation_menu && HOME_BUTTON && (
                        <li>
                            <Link to={`/`}>Accueil</Link>
                        </li>
                    )) ||
                    null}
                {sequence_back_button && (
                    <NavItem to={`/`} nav_item_style={nav_item_style} nav_item_emphasis_style={nav_item_emphasis_style}>
                        &lt; Retour
                    </NavItem>
                )}
                {display_sequence_navigation_menu && source_sequence && source_sequence_group && (
                    <NavItem
                        key={source_sequence.id}
                        sequence={source_sequence}
                        sequence_id={sequence_id}
                        sequence_group={source_sequence_group}
                        nav_item_style={nav_item_style}
                        nav_item_emphasis_style={nav_item_emphasis_style}
                    >
                        &lt;
                    </NavItem>
                )}
                {(display_sequence_navigation_menu &&
                    sequence_array.length > 1 &&
                    sequence_array.map((seq) => {
                        const sequence_group = sequence_group_array.find((sg) => sg.id === sequence.sequence_group_id)
                        return (
                            <NavItem
                                key={seq.id}
                                sequence={seq}
                                sequence_id={sequence_id}
                                sequence_group={sequence_group}
                                nav_item_style={nav_item_style}
                                nav_item_emphasis_style={nav_item_emphasis_style}
                            />
                        )
                    })) ||
                    null}
            </ul>
        </nav>
    )
})
Navigation.displayName = 'Navigation'

const NavItem = React.memo(function NavItem(props: any) {
    const { sequence, sequence_id, sequence_group, nav_item_style, nav_item_emphasis_style, to } = props
    const [hovered, setHovered] = useState(false)
    const em = to ? hovered : sequence?.id === sequence_id || hovered
    return (
        <li key={to || sequence.id}>
            <Link
                to={to || `/${sequence_group.url || sequence_group.id}/${sequence.url || sequence.id}`}
                className="p-3 block text-center"
                style={em ? nav_item_emphasis_style : nav_item_style}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {props.children || sequence.label}
            </Link>
        </li>
    )
})

export default Navigation
